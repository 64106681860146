<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <v-icon class="mr-3" color="primary"> mdi-tag-multiple </v-icon>
            <h3>Nova Categoria</h3>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              :to="{ name: 'category-list' }"
              exact
            >
              Voltar
            </v-btn>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="validForm" lazy-validation>
                  <v-text-field
                    v-model="category_form.name"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    dense
                    required
                    name="category-name"
                  >
                  </v-text-field>
                  <v-card outlined>
                    <v-card-text class="subtitle-1">
                      Selecione um icone
                    </v-card-text>
                    <div class="pa-4">
                      <v-chip-group
                        v-model="selectedIcon"
                        active-class="primary"
                        column
                        name="category-icon-group"
                      >
                        <v-chip
                          v-for="icon in icons"
                          :key="icon.name"
                          :value="icon.name"
                          :name="icon.name"
                        >
                          <v-icon>{{ icon.name }}</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="saveCategoryItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      category_form: {
        name: "",
        icon: ""
      },
      creating: false,
      valid: true,
      nameRules: [v => !!v || "Nome obrigatório"],
      dialogAdd: true,
      selectedIcon: ""
    };
  },
  async mounted() {
    await this.loadCategoriesList().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
  },

  computed: {
    ...mapState("icon", ["icons"]),
    ...mapState("categories", ["category_save"])
  },

  methods: {
    ...mapActions("categories", ["saveNewCategory", "loadCategoriesList"]),

    async saveCategoryItem() {
      this.category_form.icon =
        this.selectedIcon == undefined || this.selectedIcon == ""
          ? ""
          : this.selectedIcon;
      if (this.$refs.validForm.validate()) {
        this.creating = true;
        const params = this.category_form;
        await this.saveNewCategory(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.category_save.success) {
          this.$swal({
            icon: "success",
            title: this.category_save.message,
            showCancelButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "category-list"
              });
            } else {
              this.$refs.validForm.reset();
              this.selectedIcon = "";
              this.$refs.validForm.resetValidation();
            }
          });
        } else {
          this.$swal("Oops ...", this.category_save.message, "warning");
        }
        this.$emit("closeDialogAdd");
        this.creating = false;
      }
    }
  }
};
</script>
